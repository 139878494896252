import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import Helmet from 'react-helmet';
import Button from '../components/Button';
import Layout from '../components/Layout';
import Context from '../context/Context';
import { widths } from '../utils/constants';

const Title = ({ title, colorScheme, primaryColor }) => (
  <h3
    className={css(styles.title)}
    style={{ color: colorScheme ? colorScheme.textColor : primaryColor }}
  >
    {title}
  </h3>
);

const Image = ({ img, className }) => (
  <img
    src={img.file.url}
    alt={img.title}
    className={css(styles.image, className)}
  />
);

const ColumnSection = ({
  group: { colorScheme, button, image, title, text },
  index,
  primaryColor,
}) => (
  <div className={css(styles.group, styles.columns)}>
    {image && <Image img={image} className={styles.columnImage} />}
    <div className={css(styles.columnText)}>
      <Title
        title={title}
        colorScheme={colorScheme}
        primaryColor={primaryColor}
      />
      <div className={css(styles.text)}>{text.text}</div>
      {button && (
        <div className={css(styles.buttonRow)}>
          <Button button={button} primaryColor={primaryColor} />
        </div>
      )}
    </div>
  </div>
);

const BoxLayout = ({
  group: { colorScheme, button, image, title, text },
  index,
  primaryColor,
}) => (
  <div className={css(styles.group, styles.box)}>
    {image && <Image img={image} className={styles.boxImage} />}
    <Title
      title={title}
      colorScheme={colorScheme}
      primaryColor={primaryColor}
    />
    <div className={css(styles.text)}>{text.text}</div>
    {button && <Button button={button} primaryColor={primaryColor} />}
  </div>
);

const StackedLayout = ({
  group: { colorScheme, button, image, title, text },
  index,
  primaryColor,
}) => (
  <div className={css(styles.group, styles.flexColumn)}>
    <div>
      <Title
        title={title}
        colorScheme={colorScheme}
        primaryColor={primaryColor}
      />
      <div className={css(styles.text)}>{text.text}</div>
      {image && (
        <div className={css(styles.imageWrapper)}>
          <Image img={image} />
        </div>
      )}
      {button && <Button button={button} primaryColor={primaryColor} />}
    </div>
  </div>
);

const getSectionContent = (layout, group, primaryColor) => {
  switch (layout) {
    case 'Small image at left':
      return <ColumnSection group={group} primaryColor={primaryColor} />;
    case 'Two boxes':
      return <BoxLayout group={group} primaryColor={primaryColor} />;
    default:
      return <StackedLayout group={group} primaryColor={primaryColor} />;
  }
};

const renderSection = (section, index, primaryColor) => (
  <div key={index} className={css(styles.section)}>
    {section.contentGroups.map((group, i) => {
      const style = group.colorScheme && {
        backgroundColor: group.colorScheme.backgroundColor,
        color: group.colorScheme.textColor,
      };
      return (
        <div key={i} style={style} className={css(styles.inner)}>
          {getSectionContent(section.layout, group, primaryColor)}
        </div>
      );
    })}
  </div>
);

const Home = ({ pageContext: { year } }) => (
  <Context.Consumer>
    {({
      homepageSections = [],
      homepageHeroImageDesktop,
      homepageHeroImageMobile,
      primaryColor,
      companyName,
      heroTitle,
      logo,
    }) => (
      <Layout year={year}>
        <Helmet title={companyName}>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <h1 className={css(styles.h1)}>{logo.title}</h1>
        <h2 className={css(styles.h2)} style={{ color: primaryColor }}>
          {heroTitle}
        </h2>
        {homepageHeroImageDesktop && (
          <img
            src={homepageHeroImageDesktop.file.url}
            alt={homepageHeroImageDesktop.title}
            className={css(styles.heroImage, styles.heroDesktop)}
          />
        )}
        {homepageHeroImageMobile && (
          <img
            src={homepageHeroImageMobile.file.url}
            alt={homepageHeroImageMobile.title}
            className={css(styles.heroImage, styles.heroMobile)}
          />
        )}
        {homepageSections.map((s, i) => renderSection(s, i, primaryColor))}
      </Layout>
    )}
  </Context.Consumer>
);

export default Home;

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    [`@media ${widths.mobile}`]: {
      flexDirection: 'column',
    },
  },
  heroImage: {
    width: '100%',
  },
  heroDesktop: {
    [`@media ${widths.device}`]: {
      marginBottom: 0,
    },
    [`@media ${widths.mobile}`]: {
      display: 'none',
    },
  },
  heroMobile: {
    display: 'none',
    [`@media ${widths.mobile}`]: {
      display: 'block',
    },
  },
  group: {
    textAlign: 'center',
    borderRadius: 15,
    width: '100%',
    padding: 100,
    [`@media ${widths.device}`]: {
      padding: 40,
    },
  },
  inner: {
    flex: 1,
    borderRadius: 15,
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 32,
    margin: '30px 0 60px',
    fontWeight: 'bold',
    lineHeight: 1.3,
    [`@media ${widths.desktopSmall}`]: {
      fontSize: '2.7vw',
    },
    [`@media ${widths.device}`]: {
      marginBottom: 30,
      fontSize: '2.7vw',
    },
    [`@media ${widths.mobile}`]: {
      textAlign: 'center',
      fontSize: '4.2vw',
    },
    [`@media ${widths.mobileSmall}`]: {
      fontSize: '4.5vw',
    },
  },
  text: {
    lineHeight: 1.5,
    marginBottom: 60,
  },
  image: {
    maxWidth: '100%',
    display: 'block',
    margin: '20px auto',
  },
  columnImage: {
    width: '34%',
    marginRight: 90,
    [`@media ${widths.mobile}`]: {
      width: '100%',
      maxWidth: 300,
      display: 'block',
      marginRight: 'auto',
    },
  },
  columns: {
    display: 'flex',
    alignItems: 'center',
    [`@media ${widths.mobile}`]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  columnText: {
    textAlign: 'left',
  },
  box: {
    border: '1px solid #CBDCF5',
    padding: '40px 20px',
    width: 'calc(100% - 40px)',
    maxWidth: 500,
    height: '100%',
    [`@media ${widths.mobile}`]: {
      marginBottom: 20,
      maxWidth: '100%',
    },
  },
  imageWrapper: {
    width: '80%',
    margin: 'auto',
  },
  boxImage: {
    width: 140,
    marginBottom: 100,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  h1: {
    position: 'absolute',
    top: -9999,
    left: -9999,
  },
  h2: {
    textAlign: 'center',
    fontSize: 32,
    lineHeight: 1.3,
    marginTop: 20,
    [`@media ${widths.desktopSmall}`]: {
      fontSize: '2.7vw',
    },
    [`@media ${widths.tablet}`]: {
      fontSize: '2.7vw',
    },
    [`@media ${widths.mobile}`]: {
      fontSize: '4.2vw',
    },
    [`@media ${widths.mobileSmall}`]: {
      fontSize: '4.5vw',
    },
  },
  buttonRow: {
    [`@media ${widths.mobile}`]: {
      textAlign: 'center',
    },
  },
});
